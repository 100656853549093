
import { Options, Vue } from 'vue-class-component'

import { YButton } from '@pacprotocol/yanui'
import PlanRaw from "@/components/Payments/PlanRaw.vue"

@Options({
    components: {
        YButton,
        PlanRaw,
    },
})
export default class SubscriptionSuccess extends Vue {
    get description() {
        return this.$store.state?.user?.plan?.description ?? 'Free Plan'
    }
    get user_loaded() {
        return Object.keys(this.$store.state.user).length > 0
    }
    get user() {
        return this.$store.state.user
    }
}
