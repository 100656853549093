import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b8933b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subscription-success-page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_y_button = _resolveComponent("y-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user_loaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_fa, {
            icon: ['fal', 'circle-check'],
            size: "6x",
            class: "primary-color"
          }),
          (_ctx.user.plan_free)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "Thank You!"))
            : (_openBlock(), _createElementBlock("h1", _hoisted_3, "Subscription Success!")),
          (_ctx.user.plan_free)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "We're missing you already!"))
            : (_openBlock(), _createElementBlock("h2", _hoisted_5, "Thank you for your order!")),
          _createElementVNode("h3", null, "You have now " + _toDisplayString(_ctx.description) + "!", 1),
          _createVNode(_component_y_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$utils.subscription_create_portal_session())),
            type: "is-success"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Manage your billing information")
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ]))
}