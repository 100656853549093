
import { Options, Vue } from 'vue-class-component'

import { YButton } from '@pacprotocol/yanui'

@Options({
    components: {
        YButton,
    },
})
export default class Subscription extends Vue {}
